<template>
  <!-- Navbar for small screens -->
  <v-app-bar v-if="display.smAndDown" elevation="0" title="" color="#111111">
    <v-btn color="white" rounded="tile" @click="gotoHome">
      <v-icon icon="mdi-home" size="x-large" class="mr-2" /> LEEG
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn color="white" rounded="tile" @click="gotoSchedule">Schedule</v-btn>
    <v-app-bar-nav-icon variant="text" color="#ffffff" @click.stop="toggleNavDrawer"></v-app-bar-nav-icon>
  </v-app-bar>

  <!-- Navbar for medium screens (and larger) -->
  <v-app-bar v-else elevation="0" title="" color="#111111">
    <v-btn color="white" rounded="tile" @click="gotoHome">
      <v-icon icon="mdi-home" size="x-large" class="mr-2" /> LEEG
    </v-btn>

    <v-spacer></v-spacer>

    <v-btn color="white" rounded="tile" @click="gotoSchedule">Schedule</v-btn>
    <v-btn color="white" rounded="tile" @click="gotoRules">League Rules</v-btn>
    <!-- <v-btn color="white" rounded="tile" @click="gotoRegistration">Registration</v-btn> -->
    <v-btn color="white" rounded="tile" @click="gotoFreeAgents">Free Agents</v-btn>
    <!--
    <v-app-bar-nav-icon variant="text" color="#ffffff" @click.stop="toggleSubNavDrawer"></v-app-bar-nav-icon>
    -->
    <v-btn color="white" rounded="tile" title="My Profile" icon @click="gotoProfile">
      <v-icon icon="mdi-account" size="large" />
    </v-btn>
  </v-app-bar>

  <!-- Drawer for all navbar items on small screens -->
  <v-navigation-drawer v-model="showNavDrawer" location="end" temporary color="grey-darken-4">
    <li class="d-flex justify-end">
      <v-btn rounded="tile" color="grey-darken-4" text-color="white" @click="gotoProfile">My Profile</v-btn>
    </li>
    <li class="d-flex justify-end">
      <v-btn rounded="tile" color="grey-darken-4" text-color="white" @click="gotoRules">League Rules</v-btn>
    </li>
    <!--
    <li class="d-flex justify-end">
      <v-btn rounded="tile" color="grey-darken-4" text-color="white" @click="gotoRegistration">Registration</v-btn>
    </li>
    -->
    <!-- <li class="d-flex justify-end">
      <v-btn rounded="tile" color="grey-darken-4" text-color="white" @click="gotoSchedule">Schedule</v-btn>
    </li> -->
    <li class="d-flex justify-end">
      <v-btn rounded="tile" color="grey-darken-4" text-color="white" @click="gotoFreeAgents">Free Agents</v-btn>
    </li>
    <!--
    <li class="d-flex justify-end">
      <v-btn rounded="tile" color="grey-darken-4" text-color="white" @click="gotoCoaches">Coach Game Reports</v-btn>
    </li>
    -->
  </v-navigation-drawer>

  <!-- Drawer for sub-nav links (game reports) on medium/large screens -->
  <!--
  <v-navigation-drawer v-model="showSubNavDrawer" location="end" temporary color="grey-darken-4">
    <v-spacer></v-spacer>
    <li class="d-flex justify-end">
      <v-btn rounded="tile" color="grey-darken-4" text-color="white" @click="gotoCoaches">Coach Game Reports</v-btn>
    </li>
    <li class="d-flex justify-end">
      <v-btn rounded="tile" color="grey-darken-4" text-color="white" @click="gotoRefereeReport">Game Report</v-btn>
    </li>
  </v-navigation-drawer>
  -->

  <!-- Drawer for account links on medium/large screens -->
  <!-- No longer implemented commenting out for now 
  <v-navigation-drawer v-model="showAccountDrawer" location="end" temporary color="grey-darken-4">
    <v-spacer></v-spacer>
    <li class="d-flex justify-end">
      <v-btn rounded="tile" color="grey-darken-4" text-color="white" @click="gotoProfile">My Profile</v-btn>
    </li>
  </v-navigation-drawer>
  -->
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useDisplay } from 'vuetify'
import { rootUrl } from '@/utils/helpers/urlHelpers'
//import { subdomainUrl } from '@/utils/helpers/urlHelpers'
//import { currentSubdomain } from '@/utils/helpers/urlHelpers'

const adminRoles = ['engineer', 'owner', 'admin', 'manager']
const ownerRoles = ['engineer', 'owner', 'admin']

const router = useRouter()

const gotoHome = () => (location.href = rootUrl())
const gotoRules = () => router.push({ name: 'rules' })
const gotoSchedule = () => router.push({ name: 'schedule' })
const gotoFreeAgents = () => router.push({ name: 'freeAgents' })

const gotoProfile = () => (location.href = rootUrl() + 'profile') // { router.push({ name: 'profile' }) }

//const gotoRefereeReport = () => router.push({ name: 'refereeReport' })
// const gotoRegistration = () => router.push({ name: 'registration' })
// const gotoCoaches = () => router.push({ name: 'coaches' })

const showNavDrawer = ref(false)
const showSubNavDrawer = ref(false)
const showAccountDrawer = ref(false)
const display = ref(useDisplay())

const toggleNavDrawer = () => {
  showNavDrawer.value = !showNavDrawer.value
}
const toggleSubNavDrawer = () => {
  showAccountDrawer.value = false
  showSubNavDrawer.value = !showSubNavDrawer.value
}

const toggleAccountDrawer = () => {
  showSubNavDrawer.value = false
  showAccountDrawer.value = !showAccountDrawer.value
}

</script>
