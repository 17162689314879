import { acceptHMRUpdate, defineStore } from 'pinia'
import { ISnackbarState } from '@/types/snackbars'

// TODO: convert snackbar into an array of snackbars so that multiple messages can be stacked

const initialState: ISnackbarState = {
  message: '',
  show: false,
  type: 'info',
  configs: {
    location: 'top center',
    duration: 5000,
    variant: 'elevated',
    buttonColor: 'white'
  },
}

let interval: number | null = null

const clearSnackbarInterval = () => {
  if (interval) {
    clearInterval(interval)
    interval = null
  }
}

export const useSnackbarStore = defineStore('snackbar.store', {
  state: (): ISnackbarState => ({ ...initialState }),
  getters: {
    isShow: (state: ISnackbarState) => !!state.show,
  },
  actions: {
    showSnackbar(
      message: ISnackbarState['message'],
      type: ISnackbarState['type'] = 'info',
      configs?: Partial<ISnackbarState['configs']>,
    ) {
      clearSnackbarInterval()

      this.message = message
      this.show = true
      this.type = type
      this.configs = { ...this.configs, ...configs }

      interval = setTimeout(() => {
        this.show = false
      }, this.configs.duration)
    },

    showSnackbarInternalError(message: ISnackbarState['message']) {
      clearSnackbarInterval()

      this.message = message
      this.show = true
      this.type = 'warning'
      this.configs = {
        ...this.configs,
        ...{
          location: 'bottom center',
          buttonColor: 'error',
          variant: 'elevated'
        }
      }

      console.log('showSnackbar this.configs', this.configs)

      interval = setTimeout(() => {
        this.show = false
      }, this.configs.duration)
    },

    showSnackbarValidationError(message: ISnackbarState['message']) {
      clearSnackbarInterval()

      this.message = message
      this.show = true
      this.type = 'error'
      this.configs = {
        ...this.configs,
        ...{
          location: 'top',
          // buttonColor: 'error',
          variant: 'elevated'
        }
      }

      console.log('showSnackbar this.configs', this.configs)

      interval = setTimeout(() => {
        this.show = false
      }, this.configs.duration)
    },

    hideSnackbar() {
      clearSnackbarInterval()

      this.message = ''
      this.show = false
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSnackbarStore, import.meta.hot))
}
