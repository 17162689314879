// Find the root domain (theleeg.com) so that we can easily add a subdomain
export const rootDomain = () => {
  const pathParts = location.hostname.split('.')
  if (pathParts.includes('localhost')) {
    return 'localhost:3000'
  } else if (pathParts.includes('lvh')) {
    return 'lvh.me:3000'
  } else {
    const rootDomain = pathParts.reverse().splice(0,2).reverse().join('.')
    return rootDomain
  }
}

// Find the home page (www.theleeg.com)
export const rootUrl = () => {
  const subdomain = rootDomain() == 'theleeg.com' ? 'www' : null
  return subdomainUrl(subdomain)
}

// Generate a url for the subdomain param
export const subdomainUrl = (subdomain) => {
  // If the subdomain is undefined/null, return rootDomain url
  if (!subdomain) {
    return `//${rootDomain()}/`
  }

  return `//${subdomain}.${rootDomain()}/`
}

export const loginUrl = () => {
  return rootUrl() + 'login'
}

export const logoutUrl = () => {
  return rootUrl() + 'logout'
} 

export const signupUrl = () => {
  return rootUrl() + 'signup'
}

export const currentSubdomain = () => {
  let subdomain = ''
  const pathParts = location.hostname.split('.')

  if (pathParts.includes('localhost')) {
    const rootIndex = pathParts.indexOf('localhost')
    subdomain = rootIndex > 0 ? pathParts.slice(0, rootIndex).join('.') : ''
  } else if (pathParts.includes('lvh')) {
    const rootIndex = pathParts.indexOf('lvh')
    subdomain = rootIndex > 0 ? pathParts.slice(0, rootIndex).join('.') : ''
  } else {
    subdomain = pathParts.slice(0, -2).join('.')
  }

  return subdomain
}
