<template>
  <v-footer app absolute color="grey">
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in footerLinks"
        :key="link.routeName"
        color="white"
        variant="text"
        @click="handleLink(link)"
      >
        {{ link.title }}
      </v-btn>

      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <a :href="rootUrl()" class="white text-decoration-none">
          <strong>TheLeeg</strong>
          {{ currentYear }}
        </a>
      </div>
    </v-row>
  </v-footer>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { rootUrl } from '@/utils/helpers/urlHelpers'

const router = useRouter()
const currentYear = new Date().getFullYear()

// TODO: Move to a shared file?
const footerLinks = [
  // { title: 'Home', routeName: 'home' },
  // { title: 'About', routeName: 'about' },
];

const handleLink = (link) => {
  router.push({
    name: link.routeName
  })
}

</script>
