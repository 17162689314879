import { AxiosResponse } from 'axios';
import { http, setHTTPHeader } from '@/services/http.service';
import { IRegisterUser, IUserLogin } from '@/types/general';

export const login = (user: IUserLogin): Promise<AxiosResponse> => {
  return http.post('/users/login', { user });
}

export const logout = (): Promise<AxiosResponse> => {
  return http.delete('/users/logout');
}

export const signup = (user: IRegisterUser): Promise<AxiosResponse> => {
  return http.post('/users/signup', { user });
}

export const register = (user: IRegisterUser): Promise<AxiosResponse> => {
  return http.put('/users/invitation', { user });
}

export const resetPassword = (email: string): Promise<AxiosResponse> => {
  return http.post('/users/password', { email });
}

export const fetchCurrentUser = (): Promise<AxiosResponse> => {
  return http.get('/api/users/current')
}

export const fetchInvitedUser = (invitationToken: string): Promise<AxiosResponse> => {
  return http.get(`/api/users/invited?invitation_token=${invitationToken}`)
}

export const fetchResetUser = (resetToken: string): Promise<AxiosResponse> => {
    return http.get(`/users/password/edit_post?reset_password_token=${resetToken}`)
}

export const updatePassword = (user: IRegisterUser): Promise<AxiosResponse> => {
  return http.patch('/users/password', { user });
}
